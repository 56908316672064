/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Stack,
} from '@mui/joy';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import Container from '@mui/joy/Container';
import Typography from '@mui/joy/Typography';
import { useColorScheme } from '@mui/joy/styles';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import WelcomeHeading from '@/components/reusable/WelcomeHeading';
import { ForgotPasswordLink } from '@/components/signin/ForgotPasswordLink';
import { LoginInProgress } from '@/components/signin/LoginInProgress';
import { useAuth } from '@/hooks/auth';

const Signin = () => {
  const router = useRouter();
  const isLoggingIn = router.query.email?.length > 0;
  const isMobileDevice =
    typeof window !== 'undefined' && /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [email, setEmail] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const [loggingIn, setLoggingIn] = useState(isLoggingIn);
  const [usesEmail, setUsesEmail] = useState(false);
  const [usesPasswordEmail, setUsesPasswordEmail] = React.useState(null);
  const [usesPassword, setUsesPassword] = useState(null);
  const [password, setPassword] = useState('');
  const [shouldRemember, setShouldRemember] = useState(true);
  const [status, setStatus] = useState(null);
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  // Used to short-circuit the passwordless login process if the user is on a mobile device
  const [handleLoginLinkInBrowser, setHandleLoginLinkInBrowser] = useState(!isMobileDevice);

  const { systemMode } = useColorScheme();

  const redirectTo = router?.query?.redirectTo ?? '/dashboard';
  const invited_by = router?.query?.invited_by;
  const { login, startPasswordless, loginPasswordless, loginGoogle, mutate } = useAuth({
    middleware: 'guest',
    redirectIfAuthenticated: redirectTo,
  });

  const isSignatureExpired = Boolean(
    router.query.expires && new Date(router.query.expires * 1000) < new Date()
  );

  useEffect(() => {
    if (router.query.reset?.length > 0 && errors.length === 0) {
      setStatus(atob(router.query.reset));
    } else {
      setStatus(null);
    }
  }, [router.query.reset, errors]);

  const loginWithoutPassword = useCallback(async () => {
    // if the query string includes a signature parameter, we're coming from a passwordless link
    if (router.query.signature?.length > 0 && !success) {
      // we need to take the entire query string and send it to the backend to validate the signature
      const params = router.query;
      await loginPasswordless({
        setErrors,
        setStatus,
        setLoggingIn,
        ...params,
        onSuccess: (payload) => {
          setSuccess(true);
          window.location = payload?.redirect ?? '/dashboard';
        },
      });
    }
  }, [router.query]);

  useEffect(() => {
    if (router.query?.signature?.length > 0 && handleLoginLinkInBrowser && !isSignatureExpired) {
      loginWithoutPassword();
    }
  }, [router.query.signature?.length, handleLoginLinkInBrowser]);

  useEffect(() => {
    if (usesPassword === true && usesPasswordEmail && usesPasswordEmail !== email) {
      setUsesPasswordEmail(null);
      setUsesPassword(null);
    }
  }, [email]);

  const submitForm = async (event) => {
    event.preventDefault();

    login({
      email,
      password,
      remember: shouldRemember,
      path: redirectTo,
      setStatus,
      setErrors,
      setLoggingIn,
    });
  };

  const attemptLoginGoogle = async (response) => {
    await loginGoogle({
      setErrors,
      setStatus,
      setLoggingIn,
      ...response,
      path: redirectTo,
      invited_by,
      onSuccess: (payload) => {
        setSuccess(true);
        window.location = payload?.redirect ?? '/dashboard';
      },
    });
  };
  const errorMessage = () => {};

  const submitEmail = async (event, emailOverride = null) => {
    event?.preventDefault();

    let args = {
      email: emailOverride ?? email,
      remember: shouldRemember,
      path: redirectTo,
      invited_by,
      setStatus,
      setLoggingIn,
      onSuccess: (response) => {
        setUsesPassword(response?.uses_password);
        setUsesPasswordEmail(emailOverride ?? email);
        setIsNewUser(response?.is_new);
      },
    };

    if (router.query?.path?.length > 0) {
      args.path = router.query.path;
    }

    startPasswordless(args);
  };

  const handleGoBack = () => {
    setIsNewUser(false);
    setUsesPassword(null);
    setUsesPasswordEmail(null);
    setLoggingIn(false);
    router.push('/signin');
  };

  useEffect(() => {
    if (isSignatureExpired) {
      setEmail(router.query.email);
      setUsesEmail(true);
      submitEmail(null, router.query.email);
    }
  }, []);

  const checkingLoginStatus =
    loggingIn ||
    router.asPath.includes('?email=') ||
    (router.query.signature?.length > 0 && errors?.length === 0);

  let statusResponse = null;

  const platformEnvVariable = process.env.NEXT_PUBLIC_PLATFORM;

  if (platformEnvVariable === 'electron') {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          overflow: 'hidden',
          backgroundColor: 'tertiary.500',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Stack sx={{ textAlign: 'center' }} spacing={1} alignItems="flex-start">
          <Typography level="h3" sx={{ color: 'white' }}>
            Welcome to Groupthink
          </Typography>
          <Typography level="body-lg" fontWeight="lg" sx={{ color: 'tertiary.100' }}>
            Please click the link below to sign in to your Groupthink account.
          </Typography>
          <Typography level="body-md" sx={{ color: 'tertiary.100' }}>
            You&apos;ll be redirected back here after signing in.
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            component="a"
            target="_blank"
            href={process.env.NEXT_PUBLIC_FRONTEND_URL + '/desktop'}>
            Sign In to Groupthink
          </Button>
        </Stack>
      </Box>
    );
  }

  if (isSignatureExpired && !usesPassword) {
    return (
      <LoginInProgress
        setHandleLoginLinkInBrowser={setHandleLoginLinkInBrowser}
        onClick={handleGoBack}>
        <Typography level="h4">Oops! Expired magic link</Typography>
        <Typography level="body-lg">
          No worries, we&apos;ve conjured up a fresh magic link. It&apos;ll be in your inbox
          momentarily.
        </Typography>
        <Typography level="h4" sx={{ pt: 2 }}>
          Please check your email, we just sent you a new link.
        </Typography>
      </LoginInProgress>
    );
  }

  if (checkingLoginStatus && !isSignatureExpired) {
    return (
      <LoginInProgress
        setHandleLoginLinkInBrowser={setHandleLoginLinkInBrowser}
        onClick={handleGoBack}>
        <Typography
          level="h4"
          gutterBottom
          startDecorator={<CircularProgress size="sm" variant="soft" />}>
          Please wait while we sign you in...
        </Typography>
      </LoginInProgress>
    );
  }

  if (usesPassword === false) {
    return (
      <LoginInProgress
        setHandleLoginLinkInBrowser={setHandleLoginLinkInBrowser}
        onClick={handleGoBack}>
        <Typography level="h4" gutterBottom>
          We&apos;ve sent you a magic link via email. Please click the link to continue.
        </Typography>
      </LoginInProgress>
    );
  }

  return (
    <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID}>
      <Container sx={{ mt: '20%' }}>
        <Grid container spacing={0}>
          <Grid xs={12}>
            <WelcomeHeading subtitle="Please select an option below to continue." />
            <Typography level="h4" gutterBottom>
              Please select an option below to continue.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid xs={12} lg={5}>
            <Stack
              sx={{ my: 2, width: '100%', '& iframe': { colorScheme: 'normal' } }}
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}>
              <GoogleLogin
                onSuccess={attemptLoginGoogle}
                onError={errorMessage}
                size="large"
                shape="square"
                text="continue_with"
                theme={'filled_black'}
                fullWidth
              />

              <Button
                onClick={() => setUsesEmail(true)}
                variant="outlined"
                color="neutral"
                dusk="continue-with-email-button">
                Continue with Email
              </Button>
            </Stack>
            {usesEmail && (
              <form onSubmit={(e) => (usesPassword ? submitForm(e) : submitEmail(e))}>
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <Divider sx={{ my: 2 }} />
                    <FormControl required>
                      <FormLabel>Email</FormLabel>
                      <Input
                        type="email"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        dusk="email-input"
                        error={errors?.email ? true : false}
                        autoComplete="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        disabled={false}
                      />
                      {errors?.email ? (
                        <FormHelperText sx={(theme) => ({ color: theme.vars.palette.danger[500] })}>
                          {errors?.email.join(' ')}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  {usesPassword && (
                    <>
                      <Grid xs={12}>
                        <FormControl required>
                          <FormLabel>Password</FormLabel>
                          <Input
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            dusk="password-input"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            autoComplete="current-password"
                            disabled={false}
                          />
                        </FormControl>
                      </Grid>
                      <Grid xs={12} lg={9} style={{ display: 'flex', alignItems: 'center' }}>
                        <ForgotPasswordLink email={email} />
                      </Grid>
                    </>
                  )}
                  <Grid xs={12}>
                    <Checkbox
                      checked={shouldRemember}
                      dusk="remember-me-checkbox"
                      onChange={() => setShouldRemember(!shouldRemember)}
                      label="Remember me"
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      color="primary"
                      dusk="login-button"
                      loading={loggingIn}
                      disabled={loggingIn}>
                      {usesPassword ? 'Sign In' : 'Continue'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Grid>
        </Grid>
      </Container>
    </GoogleOAuthProvider>
  );
};

export default Signin;
